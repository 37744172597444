import store from '@/store'

export default function useProfile() {
  const fetchProfile = () => {
    store
      .dispatch('test-profile/fetchProfile')
      .then(response => {
        console.log(response)
      })
      .catch(() => {
        console.log('Error')
      })
  }
  return {
    fetchProfile,
  }
}

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProfile() {
      const url = 'http://phrapi.sriphat.com/api/Patient/GetPatientDetail/null/0227187'
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

<template>
  <div>
    <h1>Test Store Module</h1>
    {{ fetchProfile() }}
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useProfile from './useProfile'
import profileStoreModule from './profileStoreModule'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'test-profile'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, profileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProfile,
    } = useProfile()

    return {
      fetchProfile,
    }
  },
}
</script>
